
  import { defineComponent, ref, onMounted, computed, Ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import useProducts from "@/composables/useProducts";
  import useProductBundle from "@/composables/useProductBundle";
  import { ProductsFilter } from "@/models";
  import { formatCurrency, formatPrice } from "@/core/services/HelperServices";
  import {
    IPagedResultDtoOfGetProductBundleForViewDto,
    CreateOrEditProductBundleDto,
    ICreateOrEditProductBundleDto,
    ICreateOrEditProductBundleDefinitionDto,
  } from "@/shared/service-proxies/generated-proxies";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { useToast } from "vue-toast-notification";
  import { Actions } from "@/store/enums/StoreEnums";
  import { FileUploadDTo } from "@/models";

  export default defineComponent({
    name: "CreateOrEditProduct",
    components: {},
    setup() {
      const { products, getProducts } = useProducts();
      const {
        productBundle,
        updateOrCreateProductBundle,
        getProductBundle,
        uploadImage,
      } = useProductBundle();
      const route = useRoute();
      const router = useRouter();
      const saving = ref(false);
      const productBundleId = computed(() => route.query.id);
      const store = useStore();
      const breadcrumbs = ref({
        title: "Product Bundle Create",
        breadcrumbs: ["Product", "Create"],
      });
      const loaded = ref(false);
      const singldef: any = ref({
        quantity: 0,
        product: {},
      });

      const prodef: any = ref([]);

      const defaultFilter = ref({
        filter: "",
        skipCount: 0,
        isActiveFilter: -1,
        maxResultCount: 1000,
      } as unknown as ProductsFilter);

      const defaultData = ref({
        // id:null,
        name: "",
        displayName: "",
        description: "",
        unitPrice: "",
        area: "",
        productBundleDefinition: [],
      }) as unknown as ICreateOrEditProductBundleDto;

      let formData = ref<ICreateOrEditProductBundleDto>(defaultData);

      const imgUploaded = ref(false);
      const fileUploadImg = ref("");
      const fileUpload = ref();
      const fileUploadImage = ref({
        name: "",
        size: "",
        type: "",
      } as unknown as FileUploadDTo);
      const config = store.getters.getConfigurations;
      const permissions = config.result.auth.grantedPermissions;
      console.log(permissions);

      const addProduct = async () => {
        if (singldef.value.quantity < 1) {
          useToast().error("Quantity is required");
          return;
        }
        if (Object.keys(singldef.value.product).length < 1) {
          useToast().error("Product is required");
          return;
        }
        prodef.value.push({ ...singldef.value });
        singldef.value.quantity = "";
        singldef.value.product = {};
        console.log(prodef.value);
      };

      const removeItem = (index) => {
        prodef.value.splice(index, 1);
        console.log(prodef.value);
      };

      const getProductsData = async () => {
        await getProducts(defaultFilter.value as unknown as ProductsFilter);
        // console.log(products.value);
      };

      const selectForFile = (event) => {
        imgUploaded.value = true;
        var file = event.target.files[0];
        fileUpload.value = file;
        fileUploadImage.value = file;
        fileUploadImg.value = URL.createObjectURL(file);
      };

      const uploadFile = async () => {
        const formData: FormData = new FormData();
        formData.append("file", fileUpload.value, fileUpload.value.name);
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        formData.append("productBundleId", productBundleId.value as string);
        await uploadImage(formData);
        window.location.reload();
      };

      const clearSelection = () => {
        imgUploaded.value = false;
        fileUploadImg.value = "";
        fileUploadImage.value = {} as unknown as FileUploadDTo;
      };

      onMounted(async () => {
        if (productBundleId.value) {
          breadcrumbs.value = {
            title: "Product Bundle",
            breadcrumbs: ["Product Bundle"],
          };
          // setCurrentPageBreadcrumbs("", ["Product Bundle", "Edit"]);
        } else {
          breadcrumbs.value = {
            title: "Product Bundle Create",
            breadcrumbs: ["Product", "Create"],
          };
          // setCurrentPageBreadcrumbs("Create Product Bundle", ["Product Bundle", "Create"]);
        }

        await getProductsData();

        if (productBundleId.value) {
          await getProductBundle(productBundleId.value as string);

          if (productBundle.value) {
            var pd: any = [];
            productBundle.value.productBundleDefinitions?.forEach((item) => {
              pd.push(item.productBundleDefinition);
              prodef.value.push(item.productBundleDefinition);
            });
            formData.value = {
              ...productBundle.value.productBundle,
              id: productBundleId.value,
              productBundleDefinition: [],
            } as unknown as ICreateOrEditProductBundleDto;

            formData.value.id = productBundleId.value as string;
            prodef.value.forEach((item) => {
              var hd = products.value.find(
                (item2) => item2.product.id == item.productId
              );
              // console.log(hd, item)
              item.product = {
                product: hd?.product,
              };
            });
          }
          console.log(productBundle.value);
          loaded.value = true;
        }
      });

      return {
        formatCurrency,
        loaded,
        imgUploaded,
        fileUploadImg,
        fileUploadImage,
        productBundle,
        productBundleId,
        permissions,
        uploadFile,
        selectForFile,
        clearSelection,
        addProduct,
        formData,
        saving,
        route,
        products,
        breadcrumbs,
        prodef,
        singldef,
        removeItem,
      };
    },
  });
