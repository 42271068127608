import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import ApiService from "@/core/services/ApiService";
import { CancelToken } from "axios";
import { useToast } from "vue-toast-notification";
const toast = useToast();

import {
  ProductCategoryFilter,
  ProductsFilter,
  ProductUnitFilter,
} from "@/models";
import {
  IGetProductForViewDto,
  IGetProductCategoryForViewDto,
  ProductsServiceProxy,
  ProductCategoriesServiceProxy,
  ProductUnitsServiceProxy,
  ProductLookupTableDto,
  CreateOrEditProductDto,
  CreateOrEditProductUnitDto,
  CreateOrEditProductCategoryDto,
  ProductCategoryLookupTableDto,
  ProductUnitLookupTableDto,
  IProductUnitDto,
  IGetProductUnitForViewDto,
  ProductPriceServiceProxy,
  IGetPriceListProductPriceForViewDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableModel {
  products: Ref<Array<IGetProductForViewDto>>;
  productPriceLists: Ref<Array<IGetPriceListProductPriceForViewDto>>;
  getProductPriceList: (id: string) => void;
  productsLookUp: Ref<Array<ProductLookupTableDto>>;
  product: Ref<IGetProductForViewDto>;
  getProducts: (filters: ProductsFilter) => void;
  getProductForLookUp: () => void;
  getProduct: (id: string) => void;
  updateOrCreateProduct: (
    product: CreateOrEditProductDto,
    cancelToken: undefined | CancelToken
  ) => void;
  deleteProduct: (id: string) => Promise<boolean>;

  productUnits: Ref<Array<IGetProductUnitForViewDto>>;
  productUnitsLookUp: Ref<Array<ProductUnitLookupTableDto>>;
  productUnit: Ref<IProductUnitDto>;
  getProductUnits: (filters: ProductUnitFilter) => void;
  getProductUnitsForLookUp: () => void;
  getProductUnit: (id: string) => void;
  updateOrCreateProductUnit: (
    productUnit: CreateOrEditProductUnitDto,
    cancelToken: undefined | CancelToken
  ) => Promise<boolean>;
  deleteProductUnit: (id: string) => Promise<boolean>;

  productCategories: Ref<Array<IGetProductCategoryForViewDto>>;
  productCategoriesLookUp: Ref<Array<ProductCategoryLookupTableDto>>;
  productCategory: Ref<IGetProductCategoryForViewDto>;
  getProductCategories: (filters: ProductCategoryFilter) => void;
  getProductCategoriesForLookUp: () => void;
  getProductCategory: (id: string) => void;
  uploadImage: (formData: any) => Promise<boolean>;
  updateOrCreateProductCategory: (
    product: CreateOrEditProductCategoryDto,
    cancelToken: undefined | CancelToken
  ) => Promise<boolean>;
  deleteProductCategory: (id: string) => Promise<boolean>;
}

const useProducts = (): UsableModel => {
  const ProductClient = new ProductsServiceProxy(APP_BASE_URL, axiosClient);

  const UnitClient = new ProductUnitsServiceProxy(APP_BASE_URL, axiosClient);

  const CategoryClient = new ProductCategoriesServiceProxy(
    APP_BASE_URL,
    axiosClient
  );

  const ProductPriceListClient = new ProductPriceServiceProxy(
    APP_BASE_URL,
    axiosClient
  );

  //Products
  const productsLookUp: Ref<Array<ProductLookupTableDto>> = ref(
    null as unknown as Array<ProductLookupTableDto>
  );
  const products: Ref<Array<IGetProductForViewDto>> = ref(
    null as unknown as Array<IGetProductForViewDto>
  );
  const product: Ref<IGetProductForViewDto> = ref(
    null as unknown as IGetProductForViewDto
  );

  // Product Unit
  const productUnitsLookUp: Ref<Array<ProductUnitLookupTableDto>> = ref(
    null as unknown as Array<ProductUnitLookupTableDto>
  );
  const productUnits: Ref<Array<IGetProductUnitForViewDto>> = ref(
    null as unknown as Array<IGetProductUnitForViewDto>
  );
  const productUnit: Ref<IProductUnitDto> = ref(
    null as unknown as IProductUnitDto
  );

  const productPriceLists: Ref<Array<IGetPriceListProductPriceForViewDto>> =
    ref(null as unknown as Array<IGetPriceListProductPriceForViewDto>);

  // Product Category
  const productCategoriesLookUp: Ref<Array<ProductCategoryLookupTableDto>> =
    ref(null as unknown as Array<ProductCategoryLookupTableDto>);
  const productCategories: Ref<Array<IGetProductCategoryForViewDto>> = ref(
    null as unknown as Array<IGetProductCategoryForViewDto>
  );
  const productCategory: Ref<IGetProductCategoryForViewDto> = ref(
    null as unknown as IGetProductCategoryForViewDto
  );

  const store = useStore();

  // Product Methods
  const getProducts = async (filters: ProductsFilter) => {
    const {
      filter,
      productCodeFilter,
      productNameFilter,
      sorting,
      minUnitPriceFilter,
      maxUnitPriceFilter,
      cancelToken,
      skipCount,
      isActiveFilter,
      maxResultCount,
    } = filters;

    await ProductClient.getAll(
      filter,
      productCodeFilter,
      productNameFilter,
      maxUnitPriceFilter,
      minUnitPriceFilter,
      isActiveFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken
    )
      .then((data) => {
        products.value = data.items as unknown as Array<IGetProductForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProduct = async (id: string) => {
    await ProductClient.getProductForView(id)
      .then((data) => {
        product.value = data;
      })
      .catch((error) => {
        toast.warning(error.message);
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getProductPriceList = async (id: string) => {
    await ProductPriceListClient.getAllForProduct(id)
      .then((data) => {
        productPriceLists.value = data;
      })
      .catch((error) => {
        toast.warning(error.message);
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getProductForLookUp = async () => {
    await ProductClient.getAllProductForLookup()
      .then((data) => {
        productsLookUp.value = data as unknown as Array<ProductLookupTableDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const updateOrCreateProduct = async (
    inputData: CreateOrEditProductDto,
    cancelToken: CancelToken | undefined
  ) => {
    await ProductClient.createOrEdit(inputData, cancelToken)
      .then((data) => {
        product.value = data as unknown as IGetProductForViewDto;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const uploadImage = async (data: any) => {
    const rt = await ApiService.post("/Product/UploadImage", data)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
        return false;
      });
    console.log(rt);
    return rt;
  };

  const deleteProduct = async (id: string): Promise<boolean> => {
    const resp = await ProductClient.delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
        return false;
      });
    return resp;
  };

  // Product Unit Methods
  const getProductUnits = async (filters: ProductUnitFilter) => {
    const {
      filter,
      productUnitNameFilter,
      productUnitDescriptionFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    } = filters;

    await UnitClient.getAll(
      filter,
      productUnitNameFilter,
      productUnitDescriptionFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken
    )
      .then((data) => {
        productUnits.value =
          data.items as unknown as Array<IGetProductUnitForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProductUnitsForLookUp = async () => {
    await UnitClient.getAllProductUnitForLookup()
      .then((data) => {
        productUnitsLookUp.value =
          data as unknown as Array<ProductUnitLookupTableDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProductUnit = async (id: string) => {
    await UnitClient.getProductUnitForView(id)
      .then((data) => {
        productUnit.value = data.productUnit;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const updateOrCreateProductUnit = async (
    inputData: CreateOrEditProductUnitDto,
    cancelToken: CancelToken | undefined
  ) => {
    const response = await UnitClient.createOrEdit(inputData, cancelToken);

    console.log(response);

    return true;
  };

  const deleteProductUnit = async (id: string): Promise<boolean> => {
    await UnitClient.delete(id).catch((error) => {
      store.commit(Mutations.SET_ERROR, error.response.error);
      return false;
    });
    return true;
  };

  // Product Categories Methods
  const getProductCategories = async (filters: ProductCategoryFilter) => {
    const {
      filter,
      productCatgoryNameFilter,
      productCategoryDescriptionFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    } = filters;

    await CategoryClient.getAll(
      filter,
      productCatgoryNameFilter,
      productCategoryDescriptionFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken
    )
      .then((data) => {
        productCategories.value =
          data.items as unknown as Array<IGetProductCategoryForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProductCategoriesForLookUp = async () => {
    await CategoryClient.getAllProductCategoryForLookup()
      .then((data) => {
        productCategoriesLookUp.value =
          data as unknown as Array<ProductCategoryLookupTableDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProductCategory = async (id: string) => {
    await ProductClient.getProductForView(id)
      .then((data) => {
        product.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const updateOrCreateProductCategory = async (
    inputData: CreateOrEditProductCategoryDto,
    cancelToken: CancelToken | undefined
  ) => {
    const response = await CategoryClient.createOrEdit(inputData, cancelToken);

    console.log(response);

    return true;
  };

  const deleteProductCategory = async (id: string) => {
    await CategoryClient.delete(id).catch((error) => {
      store.commit(Mutations.SET_ERROR, error.response.error);

      return false;
    });
    return true;
  };

  return {
    products,
    getProductPriceList,
    productPriceLists,
    productsLookUp,
    product,
    getProducts,
    getProductForLookUp,
    getProduct,
    updateOrCreateProduct,
    deleteProduct,
    productUnits,
    productUnitsLookUp,
    productUnit,
    getProductUnitsForLookUp,
    getProductUnits,
    getProductUnit,
    updateOrCreateProductUnit,
    deleteProductUnit,

    productCategoriesLookUp,
    productCategories,
    productCategory,
    getProductCategoriesForLookUp,
    getProductCategories,
    getProductCategory,
    updateOrCreateProductCategory,
    deleteProductCategory,
    uploadImage,
  };
};

export default useProducts;
